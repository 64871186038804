/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
export const filtersarray = [];
// array to store the selected filters in each of the dropdown
export const itemNumberFormat = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{2})$/;
// item number Format
export const itemNumber = /^([0-9]{10})?$/;
// item number check
export const currentFYyear = (new Date().getMonth().toString()) > 8
  ? ((new Date().getFullYear()) + 1) : (new Date().getFullYear());
  // to get the current financial year
export const numberOfFinancialYears = 1;
// number of finanancial years to select
export const previousYear = (currentFYyear) - 1;
// For the previous finanacial year
export const sourceLocaleName = 'Official English';
// source language
export const notReleasedStatus = 'Not released';
// for updating the text as not released
export const spaceAfterhundreds = (originalNumber) => {
  const spacednumber = originalNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return spacednumber;
};
// function to induce space after every three number
