/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useState, useEffect } from 'react';
import Modal, {
  ModalHeader, Theatre,
} from '@ingka/modal';
import '@ingka/modal/dist/style.css';
import './ConnectedProductsModal.scss';
import Carousel from "@ingka/carousel";
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import SSRIcon from '@ingka/ssr-icon';
import imagestrikethrough from "@ingka/ssr-icon/paths/image-strikethrough";
import '@ingka/focus/style.scss';
import SitsContext from '../../../../context/SitsContext';

const imageS4 = ['https://content-test.pi-apps.net/api/content/v1/media/0875839-00001-S4/CSN7mKSrG8XxcE3tPdzLHZ5jz_HmF59zsMmlw5GrzLY=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM=',
  'https://content-test.pi-apps.net/api/content/v1/media/0823624-00001-S4/dxBIXFjX7xZ5EW8CTg5jnNhpDhqSGyUBjxOeuFL9HEk=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM=',
  'https://content-test.pi-apps.net/api/content/v1/media/0709131-00001-S4/4hHlZS_7LJF2g_-qMCzQt8lW3HyROhq1w400DK_qek4=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM=',
  'https://content-test.pi-apps.net/api/content/v1/media/0730619-00001-S4/nxypaV8syKshm0OpXJ3N564Cr3WDyd_nH9lm6tzArhg=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM=',
  'https://content-test.pi-apps.net/api/content/v1/media/0702213-00001-S4/kIBgr7pR8jMM_KtlupojJ7OZBAcwykKJ8ufcZpxvyqs=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM='];

const imageS5 = ['https://content-test.pi-apps.net/api/content/v1/media/0875839-00001-S5/J7ebazoLx4W41RHKvyiK5e6wUC_YLd7Hq5Q1fnL3iDQ=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM=',
  'https://content-test.pi-apps.net/api/content/v1/media/0823624-00001-S5/-Pp5HswpOxFuDbxMtOhBDf7QI1sTGMKYsiXJI2sgRFM=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM=',
  'https://content-test.pi-apps.net/api/content/v1/media/0709131-00001-S5/LBCnvD8P2s9kCkjw_mRLdcti-vuawYAZUXaNxB6jEAA=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM=',
  'https://content-test.pi-apps.net/api/content/v1/media/0730619-00001-S5/ZxJfgcfdejNhY6-Nm9eUM1915ed_0gWD-w0f-zpIkRU=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM=',
  'https://content-test.pi-apps.net/api/content/v1/media/0702213-00001-S5/c56M-M35eu0VdGHJNLayLobZj_vMewRzHh_Ou1IdRFk=?ref=9LedWeN0dyrDbIPa1RvdZRHFDQv8L0sp7opJrDNYCOM='];

const ConnectedProductsModal = () => {
  const sitsContext = useContext(SitsContext);
  const [modelOpen, setModelOpen] = useState(true);
  const closeButtonHandler = () => {
    setModelOpen(false);
    sitsContext.updateIsConnectImageClicked('isImageClicked', false);
  };
  return (
    <div id="modal-carousel-banner-connected-info">
      <Modal className="error-modal" handleCloseBtn={closeButtonHandler} visible={modelOpen} focusLockProps={{ disabled: true }}>
        <Theatre
          aria-label="Accessibility header for a modal"
          header={<ModalHeader ariaCloseTxt="Close button text" />}
          className="theatre_carousel"
        >
          <Carousel
            id="default-carousel"
            className="carousel_modal"
          >
            {(sitsContext.connectedProdData.itemsConnected !== null || sitsContext.connectedProdData.itemsConnected.length !== 0)
              && (sitsContext.connectedProdData.itemsConnected.map((imgData, index) => (
                imgData.bigPicURL.length !== 0
                  ? (index === sitsContext.isConnectImageClicked.imageIndex) && (
                    imgData.bigPicURL.map((bigImgData, index2) => (
                      <div className="connected_information_child_modal">
                        {(bigImgData !== null) && (
                        <img id={`connectedImage${index2}`} src={bigImgData} alt={`connectedProdImage${index2}`} className="connected_info_img_modal" />)}
                        {(bigImgData === null || (bigImgData === " ")) && (
                        <SSRIcon
                          className="connected_info_no_img_modal"
                          paths={imagestrikethrough}
                        />
                        )}
                      </div>
                    ))) : (
                      <SSRIcon
                        className="connected_info_no_img_modal"
                        paths={imagestrikethrough}
                      />
                  )
              )))}
          </Carousel>
        </Theatre>
      </Modal>
    </div>
  );
};

export default ConnectedProductsModal;
