/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import React, { useEffect, useState, useContext } from 'react';
import Pill from '@ingka/pill';
import close from '@ingka/ssr-icon/paths/cross-small';
import SitsContext from '../../../context/SitsContext';
import '../../../index.scss';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';

export default function Diplayfilters(props) {
  const sitsContext = useContext(SitsContext);
  const [displayfilters, setDisplayfilters] = useState([]);
  // intializing the displayfilters array for displaying the selected filters values
  const [displayvalue, setDisplayvalue] = useState([]);
  // intializing the displayfilters array for displaying the selected filters name
  let Updatedfilters = [];
  // updatedfilters array to capture the selected filters  values when clear action is performed
  let Updatedfiltersvalue = [];
  // updatedfilters array to capture the selected filters names when clear action is performed
  let height;
  // to capture the height of the component

  // Funtionality to capture when there is change in filter values and names
  useEffect(() => {
    setDisplayfilters(props.filters); // filter values are assigned to displayfilters array
    setDisplayvalue(props.filtervalue);// filter names are assigned to displayfilters array
  }, [props.filters, props.filtervalue]);

  // clear all Function
  const clearall = () => {
    sitsContext.updateFilterDisplayFlag(true);
    sitsContext.updateInitialFilterFlag(true);
    setDisplayfilters([]); // display filters is updated with the empty array
    props.displayfilter([], []);
    // the array values are passed to other components using props method
  };

  // Funtionality to capture when there is clear all action is performed in the searchbar
  useEffect(() => {
    if (props.clearfilters === true) {
      // eslint-disable-next-line no-use-before-define
      clearall();
    } else if (props.clearfilters === 'reset') {
      props.displayfilter(['Overdue'], ['Overdue']);
    }
  }, [props.clearfilters]);

  // Funtionality to capture height of component
  useEffect(() => {
    height = document.getElementById('container').clientHeight;
    props.heightoffilters(height);
    // the height of the component value is passed to other components using props method
  }, [displayvalue]);

  // handle change functionality , when the filter is cleared
  const change = (val, val2) => {
    sitsContext.updateFilterDisplayFlag(true);
    sitsContext.updateInitialFilterFlag(true);
    setDisplayfilters(displayfilters.filter((item) => item !== val));
    // The cleared filter value is removed from the selected filters
    Updatedfilters = displayfilters.filter((item) => item !== val);
    // updatedfilters array is updated with the new filter values
    Updatedfiltersvalue = displayvalue.filter((item) => item !== val2);
    // updatedfiltersvalue array is updated with the new filter name
    props.displayfilter(Updatedfilters, Updatedfiltersvalue);
    // Array values are passed to other components using props method
  };

  return (
    <div id="container" className="displayfilters_div">
      {displayfilters !== null
        ? displayfilters.map((array, index) => (
          <Pill
            key={index}
            onClick={() => change(array, displayvalue[index])}
            className=" Noto_font_family displayfilters "
            name={array}
            ssrIcon={close}
            selected
            iconPosition="leading"
            label={array}
            small
          />
        ))
        : ''}
      <Pill
        className=" Noto_font_family displayfilters "
        style={{ display: displayfilters.length > 1 ? '' : 'none' }}
        onClick={clearall}
        label={lablesAndnames.button_clearAllButtonText}
        small
      />
    </div>
  );
}
