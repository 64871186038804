/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import Modal, { Prompt, ModalFooter, ModalHeader } from '@ingka/modal';
import '@ingka/svg-icon/style.scss';
import '@ingka/button/style.scss';
import '@ingka/modal/dist/style.css';
import '@ingka/focus/style.scss';
import Button from '@ingka/button';
import '../../App.scss';
import { messagetobedisplayed, correlationid } from '../../pages/Searchpage';
import { correlationIdEditorPage } from '../../pages/Editorpage';
import errorMessages from '../../messages/errorMessages.json';

export default function ErrorHandler(props) {
  const [modelOpen, setModelOpen] = useState(true);
  // intializing model open to display the modal for API error response
  const onClickClose = () => {
    setModelOpen(false);
    if ((props.saveTranslation === true) || (props.errorNavToEditor === true)) {
      props.disableErrorHandler();
    }
  }; // Close button fuctionality
  return (
    <div>
      <Modal className="error-modal" handleCloseBtn={onClickClose} visible={modelOpen} focusLockProps={{ disabled: true }}>
        <Prompt
          aria-label="Accessibility header for a modal"
          className="example-prompt-override"
          footer={<ModalFooter><Button className="btn--small" text="OK" onClick={onClickClose} type="primary" /></ModalFooter>}
          header={<ModalHeader ariaCloseTxt="Close button text" />}
          title={props.saveTranslation ? props.errorMessage : 'SITS unavailable'}
        >
          {messagetobedisplayed === 1 && (
          <div>
            <p>{errorMessages.errorOnSearch}</p>
            <span>{correlationid}</span>
          </div>
          )}
          {messagetobedisplayed === 2 && (
          <div>
            <p>{errorMessages.errorOnCountLoad}</p>
            <span>{correlationid}</span>
          </div>
          )}
          {props.errorDisplayed === 3 && (
          <div>
            <p>{errorMessages.errorOnLocalLanguageSelect}</p>
            <span>{props.correlationIdForUserData}</span>
          </div>
          )}
          {props.errorDisplayed === 4 && (
          <div>
            <p>{errorMessages.errorOnAccessingFilters}</p>
            <span>{props.correlationIdForFilter}</span>
          </div>
          )}
          {props.errorDisplayed === 5 && (
          <div>
            <p>{errorMessages.errorOnReleasingTexts}</p>
            <span>{correlationIdEditorPage}</span>
          </div>
          )}
          {props.errorDisplayed === 6 && (
          <div>
            <p>{errorMessages.errorOnOpeningEditor}</p>
            <span>{correlationid}</span>
          </div>
          )}
          {props.errorDisplayed === 7 && (
          <div>
            <p>{errorMessages.errorOnTMavailability}</p>
          </div>
          )}
          {props.errorDisplayed === 8 && (
          <div>
            <p>{errorMessages.errorOnUpdatingTM}</p>
          </div>
          )}
          {props.errorDisplayed === 9 && (
          <div>
            <p>{errorMessages.errorOnConfirmingText}</p>
            <span>{correlationIdEditorPage}</span>
          </div>
          )}
          {props.errorDisplayed === 10 && (
          <div>
            <p>{errorMessages.errorOnAutoSaving}</p>
            <span>{correlationIdEditorPage}</span>
          </div>
          )}
        </Prompt>
      </Modal>

    </div>

  );
}
