/* eslint-disable max-len */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable indent */
/* eslint-disable-next-line func-names, object-shorthand, space-before-function-paren */
/* eslint-disable-next-line comma-dangle, indent */
/* eslint-disable prefer-template */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useContext } from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { useCookies } from 'react-cookie';
import { selectedPopupValue } from '../../../App';
import { retrieveGraphdata } from '../../../api/StartpageApirequests';
import SitsContext from '../../../context/SitsContext';
import { currentFYyear, previousYear, spaceAfterhundreds } from '../../../constants';
import { dummyDatainGraph, initialDatainGraph } from '../../searchpage/data/Dummydatafor graph';

export default function Graphcomponent(props) {
  const [totalgraphdata, setTotalgraphdata] = useState([]);
  const [countloading, setcountloading] = useState(false);
  const [langvalue, setlangvalue] = useCookies(['selected_locale']);
  // const [selectedlangvalue, setSelectedlangvalue] = useState(null);
  const [previouslangvalue, setPreviouslangvalue] = useState(null);
  const sitsContext = useContext(SitsContext);
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.63);
  // Set initial width based on window size
  const [updatedvalues, setUpdatedvalues] = useState(dummyDatainGraph);

  useEffect(() => {
    if (sitsContext.editorPageStatusButton === 'loadPreviousData') {
      if (sitsContext.startpageGraphdata.length !== 0
        && sitsContext.startpageGraphdataloaded === true) {
        setcountloading(true);
        graphcomponentdata(props.graphtab === 'texts' ? sitsContext.startpageGraphdata[0].texts : sitsContext.startpageGraphdata[0].words);
        setTotalgraphdata(sitsContext.startpageGraphdata);
        setTimeout(() => {
          setcountloading(false);
        }, 300);
      } else {
        setcountloading(true);
        const financialYear = props.selectedFYyear !== undefined
          ? props.selectedFYyear : currentFYyear;
        retrieveGraphdata(langvalue.selected_locale, financialYear, getgraphdata);
      }
    } else {
      if (langvalue.selected_locale !== null) {
        setcountloading(true);
        const financialYear = props.selectedFYyear !== undefined
          ? props.selectedFYyear : currentFYyear;
        retrieveGraphdata(langvalue.selected_locale, financialYear, getgraphdata);
      }
    }
  }, []);

  useEffect(() => {
    const financialYear = props.selectedFYyear !== undefined
      ? props.selectedFYyear : currentFYyear.toString();
    setPreviouslangvalue(selectedPopupValue.selectedlangvalue);
    sitsContext.updateStartpageGraphdata(false, []);
    if (selectedPopupValue.selectedlangvalue !== previouslangvalue
      && previouslangvalue !== null && previouslangvalue !== undefined) {
      setcountloading(true);
      retrieveGraphdata(selectedPopupValue.selectedlangvalue, financialYear, getgraphdata);
    } else {
      if (selectedPopupValue.selectedlangvalue !== undefined) {
        setcountloading(true);
        retrieveGraphdata(selectedPopupValue.selectedlangvalue, financialYear, getgraphdata);
      }
    }
  }, [props.selectedFYyear, selectedPopupValue.selectedlangvalue]);

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.63); // Adjust width dynamically on window resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getgraphdata = (data) => {
    setTotalgraphdata(data);
    sitsContext.updateStartpageGraphdata(true, data);
    graphcomponentdata(props.graphtab === 'texts' ? data[0].texts : data[0].words);
    setcountloading(false);
  };

  useEffect(() => {
    if (totalgraphdata.length !== 0) {
      getgraphdata(totalgraphdata);
    }
  }, [props.graphtab]);

  function graphcomponentdata(graphdatafromapi) {
    const updatingGraphValues = JSON.parse(JSON.stringify(initialDatainGraph));
    const currentvalue = props.selectedFYyear !== undefined
      ? (props.selectedFYyear).toString() : currentFYyear.toString();
    const previousvalue = props.selectedFYyear !== undefined
      ? (props.selectedFYyear - 1).toString() : previousYear.toString();
      graphdatafromapi.forEach((statusData) => {
       if (statusData.year === previousvalue || statusData.year === currentvalue) {
        const currentindex = updatingGraphValues.findIndex((x) => x.month.toUpperCase() === statusData.month.toUpperCase());
        if (updatingGraphValues[currentindex].month.toUpperCase() === statusData.month.toUpperCase()
        && statusData.year === (updatingGraphValues[currentindex].monthvalue > 8 ? previousvalue : currentvalue)) {
        if (statusData.status === 'source') {
          updatingGraphValues[currentindex].newdata = props.graphtab === 'texts' ? statusData.no_of_texts : statusData.no_of_words;
        }
        if (statusData.status === 'released') {
          updatingGraphValues[currentindex].publisheddata = props.graphtab === 'texts' ? statusData.no_of_texts : statusData.no_of_words;
        }
       }
       }
      });
      setUpdatedvalues(updatingGraphValues);
  }

  const chartData = {
    // Define your chart data here
    labels: updatedvalues.map((data) => data.month),
    datasets: [
      {
        label: 'New',
        data: updatedvalues.map((data) => data.newdata),
        backgroundColor: countloading ? '#DFDFDF' : '#0058A3',
        borderColor: countloading ? '#DFDFDF' : '#0058A3',
        borderWidth: 1,
      },
      {
        label: 'Released',
        data: updatedvalues.map((data) => data.publisheddata),
        backgroundColor: countloading ? '#DFDFDF' : '#929292',
        borderColor: countloading ? '#DFDFDF' : '#929292',
        borderWidth: 1,
      },
      // Add more datasets if needed
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        display: countloading ? false : true,
        boxPadding: 7,
        bodyFont: {
          size: 14,
          family: 'Noto IKEA',
          weight: 400,
          style: 'normal',
          color: '#484848',
        },
        callbacks: {
          label: function (tooltipItem, data) {
            return tooltipItem.dataset.label + ': ' + spaceAfterhundreds(tooltipItem.raw);
           },
          },
      },
    },
    scales: {
      x: {
        ticks: {
          beginAtZero: true,
          align: 'center',
          display: true,
          font: {
            size: 14,
          family: 'Noto IKEA',
          weight: 400,
          style: 'normal',
          color: '#484848',
          },
        },
        grid: {
          color: '#111111',
          display: true,
          drawOnChartArea: false,
          tickLength: 5,
          z: 8,
        },
        border: {
          display: true,
          color: 'black',
        },
      },
      y: {
        ticks: {
          display: countloading ? false : true,
          font: {
            size: 14,
            family: 'Noto IKEA',
            weight: 400,
            style: 'normal',
            color: '#484848',
          },
          callback: function (value, index, ticks) {
            return spaceAfterhundreds(value);
        },
        },
        grid: {
          tickLength: 5,
          color: '#929292',
        },
        border: {
          display: true,
          color: 'black',
          dash: [4, 4],
        },

        display: true,
        title: {
          display: true,
          text: (`Number of ${props.graphtab}`),
          font: {
            size: 14,
            family: 'Noto IKEA',
            weight: 400,
            style: 'normal',
            color: '#484848',
          },
        },
      },
    },
  };

  return (
    <div className={countloading ? 'graph_container_loading' : 'graph_container'} style={{ width: `${chartWidth}px`, height: '255px' }}>
      <div className="bar_graph"><Bar data={chartData} options={chartOptions} /></div>
      {(countloading && selectedPopupValue.selectedlangvalue !== undefined) && <div className="graph_loading"><span>Loading data</span></div>}
    </div>
  );
}
