/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-sequences */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import '@ingka/table/style.scss';
import '@ingka/commercial-message/style.scss';
import CommercialMessage from '@ingka/commercial-message';
import '../../../App.scss';
import './Dueindatatable.scss';
import { useNavigate } from 'react-router-dom';
import SitsContext from '../../../context/SitsContext';
import { sourceLocaleName, spaceAfterhundreds } from '../../../constants';
import { selectedPage } from '../../../common-components/header/Header';
import Checkbox from '@ingka/checkbox';
import { selectedPopupValue } from '../../../App';
import enabledObjects from '../../../enabledObjects/enableObjects.json';

export default function Dueindatatable(props) {
  const navigate = useNavigate();
  const sitsContext = useContext(SitsContext);
  const [multipleTextids, setMultipleTextids] = useState([]);
  const [selectAll, setselectedAll] = useState(false);

  useEffect(() => {
    if (sitsContext.selectedtextsforbulkedit.length !== 0) {
      if (sitsContext.numberOfReleasedTexts !== 0) {
        const multipleidSelected = sitsContext.selectedtextsforbulkedit.filter((id) => !sitsContext.numberOfReleasedTexts.includes(id.textid.split('-')[0]));
        setMultipleTextids(multipleidSelected);
        sitsContext.updateSelectedtextsforbulkedit(multipleidSelected);
      } else if (sitsContext.numberOfReleasedTexts === 0) {
        setMultipleTextids(sitsContext.selectedtextsforbulkedit);
      }
    }
  }, []);

  useEffect(() => {
    if (sitsContext.selectedtextsforbulkedit.length !== 0) {
      setMultipleTextids(sitsContext.selectedtextsforbulkedit);
      if (sitsContext.selectedtextsforbulkedit.length === props.dueInTotalCount) {
        setselectedAll(true);
      }
    } else {
      setMultipleTextids(sitsContext.selectedtextsforbulkedit);
      setselectedAll(false);
    }
  }, [sitsContext.selectedtextsforbulkedit]);

  const selectAllIds = (e) => {
    let selectedAlltextids = [];
    if (e.target.checked === true) {
      (props.dueinTableData).map((number) => (
        // eslint-disable-next-line array-callback-return
        number.textIds.map((ids) => {
          const individualTextidData = {
            textid: ids,
            source_locale_code: 'en-ZZ',
            target_locale_code: selectedPopupValue.selectedlangvalue,
            object_type_short: number.objShortName,
            isParentEnabled: true,
          };
          selectedAlltextids.push(individualTextidData);
        })
      ));
      setselectedAll(true);
    } else if (e.target.checked === false) {
      // eslint-disable-next-line no-const-assign
      selectedAlltextids = [];
      setselectedAll(false);
    }
    setMultipleTextids(selectedAlltextids);
    sitsContext.updateSelectedtextsforbulkedit(selectedAlltextids);
  };

  // function for the redirection from the start page to the editor page
  const redirectEditor = (id, objName) => {
    let arrayOFTextIds = [];
    const selectedtext = id.map((data) => {
      const routingIndividual = {
        textid: data.indexOf('-') === -1 ? data : data.split('-')[0],
        source_locale_code: 'en-ZZ',
        target_locale_code: selectedPopupValue.selectedlangvalue,
        object_type_short: objName,
        isParentEnabled: true,
      };
      return routingIndividual;
    });

    const listOfIdsSelected = id.map((data) => {
      const routingIndividual = {
        textId: data.indexOf('-') === -1 ? data : data.split('-')[0],
        objectNameShort: objName,
      };
      if (data.split('-')[1] === 'translated') {
        arrayOFTextIds = arrayOFTextIds.concat(data.indexOf('-') === -1 ? data : data.split('-')[0]);
      }
      return routingIndividual;
    });

    sessionStorage.setItem('funcCalled', 'true');
    navigate('/Editorpage', {
      state: {
        selectedPageTitle: selectedPage,
        selectedTextData: selectedtext,
        sourceLocale: sourceLocaleName,
        targetLocale: sitsContext.selectedLocaleName,
      },
    });
    if (sitsContext.autoSaveHeaderChange !== null) {
      sitsContext.updateAutoSaveHeaderChange(null);
    }
    sitsContext.updateDataOfRelaseTexts(listOfIdsSelected, arrayOFTextIds);
    sitsContext.updateEditorSearchPageLocationData(selectedPage, selectedtext, sourceLocaleName, sitsContext.selectedLocaleName);
    sitsContext.updateFilterDisplayFlag(false);
    sitsContext.updateInitialFilterFlag(false);
    sitsContext.updateNavigateEditToSearchPageFlag(`${selectedPage}`);
  };

  // function for the Bulk Edit redirection from the start page to the editor page
  const multipleselect = (textids, objShortName, e) => {
    setselectedAll(false);
    let selectedTexts = [];
    const textidCheckedvalue = e.target.checked;

    const textIdData = textids.map((number) => {
      const individualTextidData = {
        textid: number,
        object_type_short: objShortName,
      };
      return individualTextidData;
    });

    if (textidCheckedvalue === true) {
      selectedTexts = multipleTextids.concat(textIdData);
    } else {
      selectedTexts = multipleTextids.filter((id) => !textids.includes(id.textid));
    }

    const routingData = selectedTexts.map((data) => {
      const routingIndividual = {
        textid: data.textid,
        source_locale_code: 'en-ZZ',
        target_locale_code: selectedPopupValue.selectedlangvalue,
        object_type_short: data.object_type_short,
        isParentEnabled: true,
      };
      return routingIndividual;
    });
    setMultipleTextids(routingData);
    sitsContext.updateSelectedtextsforbulkedit(routingData);
  };

  return (
    <div>
      <div>
        <Table fullWidth style={{ overflow: 'hidden' }}>
          <TableHeader sticky>
            <tr className="width_table">
              <th className="dueintable_cell_padding3 checkboxplacement">
                {' '}
                <Checkbox
                  className="checkbox_MarginChange"
                  indeterminate={multipleTextids.length !== 0 && !selectAll}
                  checked={selectAll}
                  onChange={(e) => { selectAllIds(e); }}
                />
                {' '}

              </th>
              <th className="text_align_R Num_texts_width dueintable_cell_padding">No. of texts</th>
              <th className="text_align_L objecttype_width dueintable_cell_padding1">Object type</th>
              <th className="text_align_L due_in_width dueintable_cell_padding">Due in</th>
              <th className="overdue_width"> </th>
              <th className="text_align_R Num_words_width dueintable_cell_padding2">No. of words</th>
            </tr>
          </TableHeader>
          {(props.dueinTableData).map((tabledata, dataindex) => (
            enabledObjects.objectsInDashboard.includes(tabledata.object_type)
              ? (
                <TableBody key={dataindex} className="td-body-remove-bordercolor">
                  <tr className="hide-background-color-dupl cursor_default_default width_table">
                    <td className="dueintable_cell_padding3 checkboxplacement">
                      <Checkbox
                        className="checkbox_MarginChange"
                    // eslint-disable-next-line react/jsx-boolean-value
                        checked={tabledata.textIds.every((x) => multipleTextids.find((id) => id.textid.split('-')[0] === x.split('-')[0])) === true}
                        onChange={(e) => multipleselect(tabledata.textIds, tabledata.objShortName, e)}
                      />
                    </td>
                    <td className="text_align_R Num_texts_width dueintable_cell_padding">{spaceAfterhundreds(tabledata.no_of_texts)}</td>
                    <td id="objectType" className="text_align_L objecttype_width dueintable_cell_padding1 Object_type_clickable" onClick={() => { redirectEditor(tabledata.textIds, tabledata.objShortName); }} style={{ textTransform: 'capitalize' }}>{tabledata.object_type}</td>
                    <td className={tabledata.range === 'overdue' ? 'text_align_L due_in_width dueintable_cell_padding overdue_text_color' : 'text_align_L due_in_width dueintable_cell_padding'}>
                      {tabledata.due_in !== null
                        ? (
                          `${spaceAfterhundreds(tabledata.due_in)
                          } `
                  + `day${(tabledata.due_in > 1 || tabledata.due_in < 0) ? 's' : ''}`
                        )
                        : 'Not applicable' }
                    </td>
                    <td className="overdue_width dueintable_cell_padding">
                      <div style={{ display: tabledata.range === 'overdue' ? 'block' : 'none' }}>
                        <CommercialMessage variant="nlp" message="Overdue" />
                      </div>
                    </td>
                    <td className="text_align_R Num_words_width dueintable_cell_padding2">{spaceAfterhundreds(tabledata.no_of_words)}</td>
                  </tr>
                </TableBody>
              ) : ''

          ))}
        </Table>
      </div>
    </div>
  );
}
