/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import axios from 'axios';
import {
  SITS_API_URL,
} from '../Config';
import { currentFYyear } from '../constants';
import { defaultSortValueforApi } from '../components/searchpage/filters/Sortfiltercomponent';

const retrievePendingdata = async (localeCode, success) => {
  const data = {
    locale_code: localeCode,
    financialYear: currentFYyear,
  };
  return axios
    .post(`${SITS_API_URL}/api/StartPage/PendingTranslationCount`, data)
    .then((response) => {
      success(response.data);
    });
};

const retrieveGraphdata = async (localeCode, selectedYear, success) => {
  const data = {
    locale_code: localeCode,
    financialYear: selectedYear,
  };
  return axios
    .post(`${SITS_API_URL}/api/StartPage/CountOfTextsAndWordsOfNewAndReleasedStatus`, data)
    .then((response) => {
      success(response.data);
    });
};

const retrievedueindata = async (
  selectedlocal,
  selectedFilters,
  filters,
  success,
) => {
  const data = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    sort: selectedFilters.Sortfilters === undefined
    || selectedFilters.Sortfilters.length === 0
      ? defaultSortValueforApi
      : selectedFilters.Sortfilters[0],
  };
  axios
    .post(
      `${SITS_API_URL}/api/StartPage/dueIn`,
      data,
    )
    .then((response) => {
      success(response.data, filters);
    });
};

const retrievedueindataCount = async (
  selectedlocal,
  selectedFilters,
  filters,
  success,
) => {
  const data = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    sort: selectedFilters.Sortfilters === undefined
    || selectedFilters.Sortfilters.length === 0
      ? defaultSortValueforApi
      : selectedFilters.Sortfilters[0],
  };
  axios
    .post(
      `${SITS_API_URL}/api/StartPage/dueInTextsFiltersCount`,
      data,
    )
    .then((response) => {
      success(response.data, filters);
    });
};

const retrievefiltersItemsCount = async (
  selectedlocal,
  selectedFilters,
  success,
) => {
  const dueinCountPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: [],
    status: selectedFilters.Statusfilters,
    sort: '',
  };
  const objecttypePayload = {
    locale_code: selectedlocal,
    object_type: [],
    due_in: selectedFilters.dueinfilters,
    status: selectedFilters.Statusfilters,
    sort: '',
  };
  const statusPayload = {
    locale_code: selectedlocal,
    object_type: selectedFilters.Objecttypefilters,
    due_in: selectedFilters.dueinfilters,
    status: [],
    sort: '',
  };

  const itemsCountReqs = [
    `${SITS_API_URL}/api/StartPage/dueInTextsFiltersCount`,
  ];

  const dueinRequest = axios.post(
    itemsCountReqs[0],
    dueinCountPayload,
  );
  const objecttypeRequest = axios.post(
    itemsCountReqs[0],
    objecttypePayload,
  );
  const statusRequest = axios.post(
    itemsCountReqs[0],
    statusPayload,
  );
  Promise.all([dueinRequest, objecttypeRequest, statusRequest]).then((response) => {
    success(response[0].data, response[1].data, response[2].data);
  });
};

export {
  retrievedueindata,
  retrievedueindataCount,
  retrieveGraphdata,
  retrievePendingdata,
  retrievefiltersItemsCount,
};
