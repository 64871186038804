/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import React, {
  useState, useEffect, useLayoutEffect, useRef, useContext,
} from 'react';
import Pill from '@ingka/pill';
import '@ingka/pill/style.scss';
import downarrow from '@ingka/ssr-icon/paths/chevron-down';
import uparrow from '@ingka/ssr-icon/paths/chevron-up';
import '@ingka/button/style.scss';
import './Filtercomponent.scss';
import '@ingka/svg-icon/style.scss';
import '@ingka/forms/style.scss';
import '@ingka/switch/style.scss';
import '@ingka/aspect-ratio-box/style.scss';
import '@ingka/image/style.scss';
import '@ingka/action-list/style.scss';
import '@ingka/focus/style.scss';
import ActionList, { ActionListItem } from '@ingka/action-list';
import retrievedueinfilters from '../../startpage/data/dueinfilter.json';
import { selectedPage } from '../../../common-components/header/Header';
import SitsContext from '../../../context/SitsContext';
import lablesAndnames from '../../../lablesAndnames/labelsForComponents.json';
import { spaceAfterhundreds } from '../../../constants';

let defaultDueinValueforApi = [];

function Dueinfiltercomponent(props) {
  const sitsContext = useContext(SitsContext);
  const [Checked, setChecked] = useState([]);
  const [filtername, setFiltername] = useState([]);
  const [enable, setEnable] = useState(true);
  // intializing enable to render the component when there is a change in selected filters array
  const [newarray, setNewarray] = useState([]);
  // intializing newarray to update the filters values after the clear action is performed
  const [search, setSearch] = useState(false);
  const [select, setSelect] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const ref = useRef();
  const [initStat, setInitStat] = useState(true);

  useEffect(() => {
    if (props.filterarray !== undefined) {
      if (props.filterarray[0] === 'Overdue') {
        update(['Overdue'], ['Overdue'], props.filterarray, props.filtervaluearray);
      } else if (props.filterarray !== null || props.filtervaluearray !== null) {
        update(Checked, filtername, props.filterarray, props.filtervaluearray);
      }
    }
  }, [props.filterarray, props.filtervaluearray]);

  useEffect(() => {
    if (sitsContext.previousStateDueinFilterShortName.length === 0
      && sitsContext.startpageDueinData.length === 0) {
      const checked = ['Overdue'];
      const Filtername = ['Overdue'];
      setChecked(checked);
      setFiltername(Filtername);
      update(checked, Filtername);
    } else if (sitsContext.previousStateDueinFilterShortName.length !== 0
      && sitsContext.startpageDueinData.length !== 0) {
      const checked = [...sitsContext.previousStateDueinFilterShortName];
      const Filtername = [...sitsContext.previousStateDueinFilter];
      setChecked(checked);
      setFiltername(Filtername);
      update(checked, Filtername);
      sitsContext.updatePreviousDueinFilter(
        sitsContext.previousStateDueinFilter,
        sitsContext.previousStateDueinFilterShortName,
      );
    }
  }, []);

  useEffect(() => {
    if (typeof props.dueinFilterCount !== 'undefined') {
      setInitStat(false);
      retrievedueinfilters.forEach((dueinFilter) => {
        if (dueinFilter.itemCount) delete dueinFilter.itemCount;
        props.dueinFilterCount.forEach((dueinApiCount) => {
          if (
            dueinApiCount.groupName.toUpperCase()
            === dueinFilter.due_in_filter_value.toUpperCase()
          ) {
            dueinFilter.itemCount = dueinApiCount.itemCount;
          }
        });
      });
    }
  }, [props.dueinFilterCount]);

  // update function is called when there is any change in the search button in the dropdown
  const onClickButton = () => {
    setModalOpen(!isModalOpen);
    setSelect(!select);
  };

  useOnClickOutside(ref, () => {
    setModalOpen(false);
    setSelect(false);
  });

  // update function is called when there is any change in the selected filters
  const handleChange = (e) => {
    sitsContext.updateintialfiltervalue(true);
    const selectedvalue = e.target.value;
    const selectedfilter = e.target.name;
    const currentIndex2 = Checked.indexOf(selectedvalue);
    // capturing the index value of target value
    const newChecked = [...Checked]; // the new checked array is appended with previous values
    const currentIndex1 = filtername.indexOf(selectedfilter);
    // capturing the index value of target name
    const newfiltername = [...filtername]; // the filtername array is appended with previous values

    // checking the index is -1 , if the index is -1 the value will be  pushed into the array
    if (currentIndex2 === -1 || currentIndex1 === -1) {
      newChecked.push(selectedvalue);
      newfiltername.push(selectedfilter);
    } else {
      newChecked.splice(currentIndex2, 1);
      newfiltername.splice(currentIndex1, 1);
    } // if the index is not  -1 the value will be spliced from the array
    setChecked(newChecked); // array is updated with new values
    setFiltername(newfiltername); // array is updated with new values
    update(newChecked, newfiltername);
  };

  const update = (
    newChecked,
    newfiltername,
    cancelledarray,
    cancelledarrayvalue,
  ) => {
    const filtervalues = {
      selectedfilters:
        cancelledarrayvalue === null || cancelledarrayvalue === undefined
          ? newChecked
          : (newChecked.filter((item) => cancelledarrayvalue.includes(item))),
      selectedfiltersName:
        cancelledarray === null || cancelledarray === undefined
          ? newfiltername
          : newfiltername.filter((item) => cancelledarray.includes(item)),
      searchselected: search,
    }; // array for combining all the filters data
    setEnable(true);
    setFiltername(filtervalues.selectedfiltersName);
    // filtersname array will be upadted with the new values
    setChecked(filtervalues.selectedfilters);
    // filtersvalues array will be upadted with the new values
    newArray(filtervalues.selectedfiltersName);
    sitsContext.updatePreviousDueinFilter(
      filtervalues.selectedfiltersName,
      filtervalues.selectedfilters,
    );
    sitsContext.updateintialloading(true);

    props.DueinFilter(filtervalues);
    // filtersdata is passed to other components through the props method
    setSearch(false);
  };

  const newArray = (selectedvalues) => {
    if (selectedvalues !== undefined) {
      const defaultValue = retrievedueinfilters[0].due_in_filter_name;
      setNewarray(selectedvalues);
      defaultDueinValueforApi = retrievedueinfilters[0].due_in_filter_name;
    // array is updated with new values
    }
  };

  return (
    <div ref={ref}>
      <div>
        <div>
          <Pill
            className="Noto_font_family"
            disabled={selectedPage === 'Dashboard' ? false : true}
            selected={!!select}
            small
            label={lablesAndnames.filter_labelForDueInfilter}
            iconPosition="trailing"
            ssrIcon={select ? uparrow : downarrow}
            onClick={onClickButton}
          />
        </div>
      </div>

      {enable && (
        <div style={{ display: isModalOpen ? '' : 'none' }} className="parent_div_filter_position">
          <div className="dropdowndiv">
            <div>
              <ActionList className="action-lists Due_in_dropdown" id="Duein_Filter" variant="compact">
                {retrievedueinfilters.map((duein, index) => (
                  <ActionListItem
                    key={index}
                    control="checkbox"
                    controlProps={{
                      value: duein.due_in_filter_value,
                      name: duein.due_in_filter_name,
                      checked:
                      newarray.includes(duein.due_in_filter_name) === true,
                    }}
                    label={duein.due_in_filter_name}
                    onChange={handleChange}
                    quantityLabel={
                      duein.itemCount === undefined ? 0 : spaceAfterhundreds(duein.itemCount)
                    }
                    disabled={
                      !!(duein.itemCount === undefined && !initStat)
                    }
                    className={initStat ? 'count-zero' : 'count-list'}
                  />
                ))}
              </ActionList>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Dueinfiltercomponent;

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};
